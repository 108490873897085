import {
  Settings,
  DateTime,
} from 'luxon';

Settings.defaultZoneName = 'Europe/Amsterdam';
Settings.defaultLocale = 'nl-NL';

/* eslint-disable import/prefer-default-export */
export {
  DateTime,
};
