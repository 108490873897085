<template>
  <v-row v-if="analyticsData">
    <v-col cols="12">
      <v-card>
        <v-app-bar
          color="primary"
          dark
        >
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <div style="position: relative; max-width: 400px; margin: 0 auto;">
                <wolk-doughnut
                  v-if="chartAnalyticsData"
                  :chart-data="chartAnalyticsData"
                />
              </div>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>

                      <th class="text-left">
                        Status
                      </th>

                      <th class="text-left">
                        Aantal
                      </th>

                      <th class="text-left">
                        Verberg
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="statusCountKey in statusCountKeys"
                      :key="statusCountKey"
                    >
                      <td>
                        <div>
                          <v-sheet
                            :color="getStatusInfo(statusCountKey).color"
                            width="25"
                            height="25"
                            elevation="2"
                            tile
                          />
                        </div>
                      </td>

                      <td>
                        {{ getHumanizedWolkStatusTitle(statusCountKey) }}
                      </td>

                      <td>
                        {{ analyticsData.statusCounts[statusCountKey] }}
                      </td>

                      <td>
                        <v-checkbox
                          :input-value="getCheckboxValue(statusCountKey)"
                          @click.stop="toggleStatus(statusCountKey)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  getStatusInfo,
  getHumanizedWolkStatusTitle,
} from '../helpers/humanizer';

import WolkDoughnut from './WolkDoughnut.vue';

export default {
  name: 'WolkStatusesBlock',
  components: {
    WolkDoughnut,
  },
  props: {
    title: {
      type: String,
      default: 'Wolk statussen',
    },
    analyticsData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      disabledStatuses: [
        'offline',
      ],
    };
  },
  computed: {
    chartAnalyticsData() {
      if (!this.analyticsData) {
        return {};
      }

      const disabledStatusesObject = {};
      this.disabledStatuses.forEach((disabledStatus) => {
        disabledStatusesObject[disabledStatus] = 0;
      });

      const chartData = {
        ...this.analyticsData.statusCounts,
        ...disabledStatusesObject,
      };

      const labels = Object.keys(chartData).map(getHumanizedWolkStatusTitle);
      const counts = Object.values(chartData);
      const colors = Object.keys(chartData).map((s) => getStatusInfo(s).color);

      return {
        labels,
        datasets: [{
          data: counts,
          backgroundColor: colors,
          borderWidth: 0,
        }],
      };
    },
    statusCountKeys() {
      if (!this.analyticsData || !this.analyticsData.statusCounts) {
        return [];
      }

      return Object.keys(this.analyticsData.statusCounts).filter((k) => k !== 'offline');
    },
  },
  methods: {
    getStatusInfo(key) {
      return getStatusInfo(key);
    },
    getHumanizedWolkStatusTitle(key) {
      return getHumanizedWolkStatusTitle(key);
    },
    getCheckboxValue(statusKey) {
      return this.disabledStatuses.includes(statusKey);
    },
    toggleStatus(statusKey) {
      if (this.disabledStatuses.includes(statusKey)) {
        this.disabledStatuses = this.disabledStatuses.filter((s) => s !== statusKey);

        return;
      }

      this.disabledStatuses = [...this.disabledStatuses, statusKey];
    },
  },
};
</script>
