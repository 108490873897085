import { decodeJwt } from '../helpers/auth';

export default {
  namespaced: true,
  state: {
    jwt: null,
    type: null,
    id: null,
    metaData: null,
    exp: null,
  },
  mutations: {
    jwtData(state, payload) {
      if (!payload || !payload.jwt) {
        localStorage.removeItem('jwt');
        state.jwt = null;
        state.exp = null;
        state.type = null;
        state.id = null;
        state.metaData = null;

        return;
      }
      localStorage.setItem('jwt', payload.jwt);

      state.jwt = payload.jwt;
      state.exp = payload.exp;
      state.type = payload.type;
      state.id = payload.id;
      state.metaData = payload.metaData;
    },
  },
  actions: {
    loadJwt({ dispatch }) {
      // console.log('Loading JWT from localstorage');
      const jwtString = localStorage.getItem('jwt');

      if (!jwtString) {
        return;
      }

      dispatch('setJwt', jwtString);
    },
    setJwt({ commit }, jwtString) {
      const {
        jwtData,
        valid,
      } = decodeJwt(jwtString);

      if (!valid) {
        return;
      }

      commit('jwtData', {
        jwt: jwtString,
        exp: jwtData.exp,
        type: jwtData.type,
        id: jwtData.id,
        metaData: jwtData.metaData,
      });
    },
  },
  getters: {
    isAdmin(state) {
      return state.type === 'admin';
    },
    isUser(state) {
      return state.type === 'user';
    },
    isAdminUser(state) {
      return state.type === 'user' && state.metaData.isAdmin;
    },
  },
};
