<script>
import { Doughnut, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: [
    reactiveProp,
  ],
  mounted() {
    const options = {
      responsive: true,
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      cutoutPercentage: 60,
      circumference: 1.2 * Math.PI,
      rotation: 0.9 * Math.PI,
    };

    this.renderChart(this.chartData, options);
  },
};
</script>
