import jwtDecode from 'jwt-decode';

const decodeJwt = (jwtString) => {
  const decoded = jwtDecode(jwtString);

  if (!decoded.exp || decoded.exp < Math.floor(Date.now() / 1000)) {
    return {
      valid: false,
      jwtData: decoded,
    };
  }

  return {
    valid: true,
    jwtData: decoded,
  };
};

const jwtValid = (jwt) => {
  if (!jwt) {
    return false;
  }

  const { valid } = decodeJwt(jwt);
  return valid;
};

const nonSignedInRouteNames = [
  'signIn',
  'setPassword',
  'newSetPasswordRequest',
];

export {
  decodeJwt,
  jwtValid,
  nonSignedInRouteNames,
};
