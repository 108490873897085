<template>
  <div>
    <v-tooltip
      v-if="tooltip"
      top
    >
      <template #activator="{ on, attrs }">
        <v-btn
          :color="color"
          icon
          v-bind="attrs"
          v-on="on"
          @click="dialogOpen = true;"
        >
          <v-icon>
            mdi-information-outline
          </v-icon>
        </v-btn>
      </template>

      <span>{{ tooltip }}</span>
    </v-tooltip>

    <v-btn
      v-else
      :color="color"
      icon
      @click="dialogOpen = true;"
    >
      <v-icon>
        mdi-information-outline
      </v-icon>
    </v-btn>

    <v-dialog
      v-if="dialogType"
      v-model="dialogOpen"
      width="400"
    >
      <v-card>
        <template v-if="dialogType === 'dataNotReceived'">
          <v-card-title class="headline grey lighten-2">
            Data ontvangen
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              in verband met ontbreken van de data, zijn deze activaties niet geanalyseerd.
            </p>

            <p>
              Data kan ontbreken omdat deze nog niet is verzonden,
              of omdat er iets mis is gegaan in de communicatie tussen Wolk en server
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'notFallInPostProcessing'">
          <v-card-title class="headline grey lighten-2">
            Onterechte activatie
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              Wolk is helaas onterecht geactiveerd, het betrof hier geen heupval.
              Onze excuses voor het ongemak.
            </p>

            <p>
              De situatie wordt onderzocht om het val-algoritme te verbeteren.
            </p>

            <p>
              Was het wel een val? Wij ontvangen er graag een melding van via
              <a href="mailto:feedback@wolkairbag.com">feedback@wolkairbag.com</a>
              Bij voorbaat dank.
            </p>
          </v-card-text>
        </template>

        <template v-else-if="dialogType === 'notOnTimeActivated'">
          <v-card-title class="headline grey lighten-2">
            Activatie niet op tijd
          </v-card-title>

          <v-card-text class="mt-4">
            <p>
              De airbag van Wolk heeft 0,1 seconde nodig om volledig op te blazen.
              Als een heupval "laat" gedetecteerd wordt kan het zijn dat de Wolk
              niet genoeg tijd heeft om, voordat cliënt de grond raakt, volledig op te blazen.
            </p>

            <p>
              Hoe kan dit voorkomen?
            </p>

            <p>
              <ul>
                <li>
                  Vb1: Client valt eerst naar voren en draait op het laatste moment naar de zijkant.
                  Door de late zijwaartse beweging heeft de Wolk niet genoeg tijd om volledig
                  op te blazen
                </li>
              </ul>
            </p>

            <p>
              In uitzonderlijke gevallen activeert Wolk laat bij een harde of zeer harde val:
              deze situatie zal altijd door Wolk worden onderzocht om het
              val-algoritme te verbeteren. Bij zachte en zeer zachte vallen wordt
              het onderscheid laat / op tijd niet gemaakt omdat dit vallen zijn met zeer laag
              risico op heupletsel.
            </p>
          </v-card-text>
        </template>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="primary"
            text
            @click="dialogOpen = false"
          >
            Sluit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'WolkExtraInfoBtn',
  props: {
    color: {
      type: String,
      default: undefined,
    },
    dialogType: {
      type: String,
      default: undefined,
      validator: (value) => value === undefined || [
        'dataNotReceived',
        'notFallInPostProcessing',
        'notOnTimeActivated',
      ].includes(value),
    },
    tooltip: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
};
</script>
