<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <v-app-bar-nav-icon @click.stop="open = !open" />

    <v-toolbar-title>
      Wolk Airbag
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    open: {
      get() {
        return this.$store.state.app.navigationDrawerOpen;
      },
      set(val) {
        this.$store.commit('app/navigationDrawerOpen', val);
      },
    },
  },
};
</script>
