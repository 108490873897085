import Vue from 'vue';

import router from './router';
import store from './store';

import App from './App.vue';
import vuetify from './plugins/vuetify';

store.dispatch('auth/loadJwt');

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
