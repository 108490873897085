<template>
  <v-row v-if="activationAnalysesDataSum">
    <v-col cols="12">
      <v-card>
        <v-app-bar
          color="primary"
          dark
        >
          <v-toolbar-title v-if="$store.getters['auth/isAdmin']">
            Analyse
          </v-toolbar-title>

          <v-toolbar-title v-else>
            Analyse van {{ $store.state.app.dashboardHomeName }}
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              md="6"
            >
              <v-select
                v-if="!isSingular"
                v-model="selectedOverviewOption"
                :items="overviewOptions"
                hide-details
                item-text="nl"
                item-value="en"
                label="Selecteer dataweergave"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              md="6"
            >
              <v-select
                :items="timeframeSelectOptions"
                :value="selectedTimeframeKey"
                hide-details
                label="Selecteer periode"
                outlined
                @change="selectTimeframeOption"
              />
            </v-col>
          </v-row>

          <v-row v-if="selectedTimeframeKey === 'custom'">
            <v-col>
              <v-dialog
                ref="fromDialog"
                v-model="fromDateModal"
                :return-value.sync="fromDate"
                persistent
                width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="humanizedFromDate"
                    :hide-details="true"
                    label="Van"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="fromDate"
                  :allowed-dates="isAllowedDate"
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="fromDateModal = false"
                  >
                    Cancel
                  </v-btn>

                  <v-btn
                    text
                    color="primary"
                    @click="$refs.fromDialog.save(fromDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col>
              <v-dialog
                ref="toDialog"
                v-model="toDateModal"
                :return-value.sync="toDate"
                persistent
                width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="humanizedToDate"
                    :hide-details="true"
                    label="Tot"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="toDate"
                  :allowed-dates="isAllowedDate"
                  scrollable
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="toDateModal = false"
                  >
                    Cancel
                  </v-btn>

                  <v-btn
                    text
                    color="primary"
                    @click="$refs.toDialog.save(toDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row v-if="isAtFirstPossibleFromDate">
            <v-col>
              <p class="text-caption mb-0">
                Er is in het dashboard alleen data vanaf 1 augustus 2020 beschikbaar.
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-card>
                <v-list
                  two-line
                  subheader
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-progress-circular
                          v-if="activeDaysLoading"
                          size="20"
                          :width="2"
                          indeterminate
                        />
                        <template v-else>
                          {{ activeDaysExtraInfo }}
                        </template>
                        {{ isSingular ? '' : 'Wolks' }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        v-if="selectedOverviewOption === 'average'"
                      >
                        {{ isSingular ? 'is de Wolk ingezet' : 'zijn per dag ingezet' }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="selectedOverviewOption === 'total'"
                      >
                        {{ isSingular ? 'is de Wolk ingezet' : 'ingezet' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="wornSecondsPerDayValid">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ wornSecondsPerDay }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        per dag {{ isSingular ? 'heeft de Wolk' : 'hebben de Wolks' }}
                        bescherming geboden
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="showSteps">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ stepsTaken }}
                      </v-list-item-title>

                      <v-list-item-subtitle v-if="oneDaySelected">
                        stappen zijn op de geselecteerde dag gezet
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-else>
                        stappen zijn in de geselecteerde periode gezet
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-subheader
                    class="mt-2"
                    style="height: 20px;"
                  >
                    <v-row align="center">
                      <v-col cols="auto">
                        Activaties
                      </v-col>

                      <v-col>
                        <v-divider />
                      </v-col>
                    </v-row>
                  </v-subheader>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ totalOnTimeHardActivationCount }} harde / zeer harde heupvallen
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        op tijd geactiveerd
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-row
                        align="center"
                        dense
                      >
                        <v-col>
                          <v-sheet
                            color="#4CB14C"
                            width="25"
                            height="25"
                            outlined
                            tile
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ totalNotOnTimeHardActivationCount }} harde / zeer harde heupvallen
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        laat geactiveerd
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-row
                        align="center"
                        dense
                      >
                        <v-col>
                          <wolk-extra-info-btn
                            dialog-type="notOnTimeActivated"
                            tooltip="Zie toelichting"
                          />
                        </v-col>

                        <v-col>
                          <v-sheet
                            color="#FFD921"
                            width="25"
                            height="25"
                            outlined
                            tile
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ totalSoftActivationCount }} zachte / zeer zachte heupvallen
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ activationAnalysesDataSum.falsePositive }} activaties
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        zijn onterecht
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-row
                        align="center"
                        dense
                      >
                        <v-col>
                          <wolk-extra-info-btn
                            dialog-type="notFallInPostProcessing"
                            tooltip="Zie toelichting"
                          />
                        </v-col>

                        <v-col>
                          <v-sheet
                            color="#FFFFFF"
                            width="25"
                            height="25"
                            outlined
                            tile
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ activationAnalysesDataSum.nonAnalysed }} activaties
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        zijn niet geanalyseerd
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-row
                        align="center"
                        dense
                      >
                        <v-col>
                          <wolk-extra-info-btn
                            dialog-type="dataNotReceived"
                            tooltip="Zie toelichting"
                          />
                        </v-col>

                        <v-col>
                          <v-sheet
                            color="#FFFFFF"
                            width="25"
                            height="25"
                            outlined
                            tile
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-alert
                  v-if="$store.state.dev.showInvalidData && hasAnyInvalidData"
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="0"
                >
                  In de geselecteerde periode is niet alle data goed ontvangen in het dashboard.
                  Hierdoor zijn de weergegeven totalen lager dan in de werkelijkheid. Excuses voor
                  het ongemak.
                </v-alert>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <wolk-bar-chart
                v-if="true"
                :chart-data="barChartData"
                :style="
                  showSteps
                    ? 'position: relative; height: 480px; margin: 0 auto;'
                    : 'position: relative; height: 420px; margin: 0 auto;'
                "
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';

import { DateTime } from '../helpers/dateTime';

import WolkExtraInfoBtn from './WolkExtraInfoBtn.vue';
import WolkBarChart from './WolkBarChart.vue';

const dateFormat = {
  ...DateTime.DATE_FULL,
  ...{ locale: 'nl-NL' },
};

export default {
  name: 'WolkAnalyticsV3Block',
  components: {
    WolkExtraInfoBtn,
    WolkBarChart,
  },
  props: {
    scope: {
      type: String,
      default: 'global',
    },
    scopeId: {
      type: String,
      default: '',
    },
    showSteps: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fromDateModal: false,
      toDateModal: false,
      activations: null,
      activationsInvalidData: false,
      activationAnalysesDataSum: null,
      activeDays: null,
      activeDaysInvalidData: false,
      stepsTaken: null,
      stepsTakenInvalidData: false,
      wornSeconds: null,
      wornSecondsInvalidData: false,
      overviewOptions: [
        { en: 'total', nl: 'Totaal aantal Wolks ingezet' },
        { en: 'average', nl: 'Gemiddeld aantal Wolks ingezet' },
      ],
      selectedOverviewOption: 'total',
      wolkCount: null,
      cancelTokens: [],
      activeDaysLoading: true,
    };
  },
  computed: {
    oneDaySelected() {
      return this.fromDate === this.toDate;
    },
    totalOnTimeHardActivationCount() {
      return this.activationAnalysesDataSum.onTime.veryHard
        + this.activationAnalysesDataSum.onTime.hard;
    },
    totalNotOnTimeHardActivationCount() {
      return this.activationAnalysesDataSum.notOnTime.veryHard
        + this.activationAnalysesDataSum.notOnTime.hard;
    },
    totalSoftActivationCount() {
      return this.activationAnalysesDataSum.notOnTime.soft
        + this.activationAnalysesDataSum.onTime.soft
        + this.activationAnalysesDataSum.notOnTime.verySoft
        + this.activationAnalysesDataSum.onTime.verySoft;
    },
    barChartData() {
      return {
        labels: [
          'Zeer hard',
          'Hard',
        ],
        datasets: [
          {
            backgroundColor: '#4CB14C',
            label: 'Op tijd',
            data: [
              this.activationAnalysesDataSum.onTime.veryHard,
              this.activationAnalysesDataSum.onTime.hard,
            ],
            borderWidth: 0,
          },
          {
            backgroundColor: '#FFD921',
            label: 'Te laat',
            data: [
              this.activationAnalysesDataSum.notOnTime.veryHard,
              this.activationAnalysesDataSum.notOnTime.hard,
            ],
            borderWidth: 0,
          },
        ],
      };
    },
    isSingular() {
      return this.scope === 'sleeveAssignment' || this.scope === 'board';
    },
    activeDaysExtraInfo() {
      if (this.isSingular) {
        if (this.selectedOverviewOption === 'average') {
          return `${(this.activeDaysPerDay * 100).toLocaleString('nl-NL', { maximumFractionDigits: 0 })} % van de geselecteerde periode`;
        }
      }
      if (this.selectedOverviewOption === 'average') {
        return `${this.activeDaysPerDay.toLocaleString('nl-NL', { maximumFractionDigits: 0 })}`;
      }
      return this.activeDaysPerDay;
    },
    wornSecondsPerDayValid() {
      if (typeof this.wornSecondsPerDay !== 'number') {
        return false;
      }

      if (this.wornSecondsPerDay <= 0 || this.wornSecondsPerDay > 86400) {
        return false;
      }

      return true;
    },
    wornSecondsPerDay() {
      if (!this.wornSeconds) {
        return 0;
      }

      if (!this.activeDays) {
        return 0;
      }

      const wornSecondsPerDay = Math.round(this.wornSeconds / this.activeDays);
      const hours = Math.floor(wornSecondsPerDay / 3600);
      const minutes = Math.floor((wornSecondsPerDay % 3600) / 60);

      return `${hours} uur en ${minutes} ${minutes === 1 ? 'minuut' : 'minuten'}`;
    },
    fromAndToDate() {
      return `${this.fromDate}-${this.toDate}`;
    },
    humanizedFromDate() {
      return DateTime.fromISO(this.fromDate).toLocaleString(dateFormat);
    },
    humanizedToDate() {
      return DateTime.fromISO(this.toDate).toLocaleString(dateFormat);
    },
    timeframeSelectOptions() {
      return this.$store.state.analytics.timeframes.map((tf) => ({
        text: tf.title,
        value: tf.key,
      }));
    },
    radioOptions() {
      return this.$store.state.analytics.timeframes;
    },
    selectedTimeframeKey() {
      return this.$store.state.analytics.timeframeKey;
    },
    isAtFirstPossibleFromDate() {
      return this.fromDate === '2020-08-01';
    },
    fromDate: {
      get() {
        return this.$store.state.analytics.fromDate;
      },
      set(value) {
        this.$store.commit('analytics/timeframe', {
          key: 'custom',
          fromDate: value,
          toDate: this.toDate,
        });
      },
    },
    toDate: {
      get() {
        return this.$store.state.analytics.toDate;
      },
      set(value) {
        this.$store.commit('analytics/timeframe', {
          key: 'custom',
          fromDate: this.fromDate,
          toDate: value,
        });
      },
    },
    amountOfDays() {
      const fromDate = DateTime.fromISO(this.fromDate);
      const toDate = DateTime.fromISO(this.toDate);

      const diffInDays = toDate.diff(fromDate, 'days').values.days;

      if (typeof diffInDays !== 'number' || diffInDays <= -1) {
        return 0;
      }

      return diffInDays + 1;
    },
    activeDaysPerDay() {
      if (this.amountOfDays <= 0) {
        return 0;
      }
      if (this.selectedOverviewOption === 'average') {
        return this.activeDays / this.amountOfDays;
      }
      if (this.selectedOverviewOption === 'total') {
        return this.wolkCount;
      }
      return 0;
    },
    hasAnyInvalidData() {
      return this.activationsInvalidData
        || this.activeDaysInvalidData
        || this.stepsTakenInvalidData
        || this.wornSecondsInvalidData;
    },
  },
  watch: {
    fromAndToDate() {
      this.loadAllData();
    },
  },
  beforeMount() {
    if (this.isSingular) {
      this.selectedOverviewOption = 'average';
    }
    this.loadAllData();
  },
  methods: {
    loadAllData() {
      if (this.cancelTokens.length > 0) {
        this.cancelTokens.forEach((token) => token());
        this.cancelTokens = [];
      }
      this.loadData('activations');
      this.loadData('activeDays');
      this.loadData('stepsTaken');
      this.loadData('wornSeconds');
    },
    selectTimeframeOption(value) {
      if (value === 'custom') {
        this.$store.commit('analytics/timeframe', {
          key: value,
          fromDate: this.fromDate,
          toDate: this.toDate,
        });

        return;
      }

      this.$store.dispatch('analytics/selectPreset', value);
    },
    loadData(metric) {
      this.loading = true;
      if (metric === 'activeDays') {
        this.activeDaysLoading = true;
      }
      const url = `${config.VUE_APP_API_BASE_URL}/analytics/v2`;

      const params = {
        scope: this.scope,
        metric,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      if (this.scope !== 'global') {
        params.scopeId = this.scopeId;
      }

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        cancelToken: new axios.CancelToken((c) => {
          this.cancelTokens.push(c);
        }),
        params,
      })
        .then((response) => {
          this[metric] = response.data.sum;
          if (metric === 'activeDays') {
            this.wolkCount = response.data.wolkCount;
          }
          this[`${metric}InvalidData`] = response.data.hasInvalidData === true;

          if (response.data.activationAnalysesDataSum) {
            this.activationAnalysesDataSum = response.data.activationAnalysesDataSum;
          }
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            console.log(error.request);
          }
        })
        .finally(() => {
          if (metric === 'activeDays') {
            this.activeDaysLoading = false;
          }
          this.loading = false;
        });
    },
    isAllowedDate(val) {
      const [year, month] = val.split('-').map((v) => parseInt(v, 10));

      if (year < 2020) {
        return false;
      }

      if (year === 2020 && month < 8) {
        return false;
      }

      return true;
    },
  },
};
</script>
