import Vue from 'vue';

import Vuetify from 'vuetify/lib';

import nl from 'vuetify/src/locale/nl.ts';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { nl },
    current: 'nl',
  },
  theme: {
    themes: {
      light: {
        primary: '#4E6CBA',
      },
    },
  },
});
