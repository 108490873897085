import Vue from 'vue';
import axios from 'axios';

import VueRouter from 'vue-router';

import store from '../store';
import { nonSignedInRouteNames } from '../helpers/auth';

import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
  },
  {
    path: '/users/new',
    name: 'usersNew',
    component: () => import(/* webpackChunkName: "usersNew" */ '../views/users/New.vue'),
  },
  {
    path: '/users/:id',
    name: 'usersShow',
    component: () => import(/* webpackChunkName: "usersShow" */ '../views/users/Show.vue'),
  },
  {
    path: '/users/:id/edit',
    name: 'usersEdit',
    component: () => import(/* webpackChunkName: "usersEdit " */ '../views/users/Edit.vue'),
  },
  {
    path: '/organizations',
    name: 'organizations',
    component: () => import(/* webpackChunkName: "organizations" */ '../views/Organizations.vue'),
  },
  {
    path: '/organizations/new',
    name: 'organizationsNew',
    component: () => import(/* webpackChunkName: "organizationsNew" */ '../views/organizations/New.vue'),
  },
  {
    path: '/organizations/:id',
    name: 'organizationsShow',
    component: () => import(/* webpackChunkName: "organizationsShow" */ '../views/organizations/Show.vue'),
  },
  {
    path: '/organizations/:id/edit',
    name: 'organizationsEdit',
    component: () => import(/* webpackChunkName: "organizationsEdit " */ '../views/organizations/Edit.vue'),
  },
  {
    path: '/wolks',
    name: 'wolks',
    component: () => import(/* webpackChunkName: "wolks" */ '../views/Wolks.vue'),
  },
  {
    path: '/wolks/:id',
    name: 'wolksShow',
    component: () => import(/* webpackChunkName: "wolksShow" */ '../views/wolks/Show.vue'),
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue'),
  },
  {
    path: '/activations',
    name: 'activations',
    component: () => import(/* webpackChunkName: "activations" */ '../views/Activations.vue'),
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "alerts" */ '../views/Alerts.vue'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications.vue'),
  },
  {
    path: '/api-apps',
    name: 'apiApps',
    component: () => import(/* webpackChunkName: "apiApps" */ '../views/ApiApps.vue'),
  },
  {
    path: '/api-apps/new',
    name: 'apiAppsNew',
    component: () => import(/* webpackChunkName: "apiAppsNew" */ '../views/apiApps/New.vue'),
  },
  {
    path: '/api-apps/:id',
    name: 'apiAppsShow',
    component: () => import(/* webpackChunkName: "apiAppsShow" */ '../views/apiApps/Show.vue'),
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import(/* webpackChunkName: "locations" */ '../views/Locations.vue'),
  },
  {
    path: '/locations/new',
    name: 'locationsNew',
    component: () => import(/* webpackChunkName: "locationsNew" */ '../views/locations/New.vue'),
  },
  {
    path: '/locations/:id',
    name: 'locationsShow',
    component: () => import(/* webpackChunkName: "locationsShow" */ '../views/locations/Show.vue'),
  },
  {
    path: '/exports',
    name: 'exports',
    component: () => import(/* webpackChunkName: "exports" */ '../views/Exports.vue'),
  },
  {
    path: '/sign-in',
    name: 'signIn',
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignIn.vue'),
  },
  {
    path: '/set-password/:setPasswordRequestPublicId',
    name: 'setPassword',
    component: () => import(/* webpackChunkName: "setPassword" */ '../views/SetPassword.vue'),
  },
  {
    path: '/set-password-requests/new',
    name: 'newSetPasswordRequest',
    component: () => import(/* webpackChunkName: "newSetPasswordRequest" */ '../views/setPasswordRequests/New.vue'),
  },
  {
    path: '/dev-settings',
    name: 'devSettings',
    component: () => import(/* webpackChunkName: "devSettings" */ '../views/DevSettings.vue'),
  },
  {
    path: '/administration/search',
    name: 'administrationSearch',
    component: () => import(/* webpackChunkName: "administrationSearch" */ '../views/administration/Search.vue'),
  },
  {
    path: '/administration/insert-and-link',
    name: 'administrationInsertAndLink',
    component: () => import(/* webpackChunkName: "administrationInsertAndLink" */ '../views/administration/InsertAndLink.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const loadMyAccount = async () => {
  const url = `${config.VUE_APP_API_BASE_URL}/my-account`;

  return axios({
    url,
    headers: {
      Authorization: `Bearer ${store.state.auth.jwt}`,
    },
    method: 'get',
  })
    .then((response) => {
      store.commit('myAccount/type', response.data.type);
      store.commit('myAccount/firstName', response.data.firstName);
      store.commit('myAccount/lastName', response.data.lastName);
      store.commit('myAccount/email', response.data.email);

      if (response.data.type === 'user') {
        store.commit('myAccount/organizationId', response.data.organizationId);
        store.commit('myAccount/organizationTitle', response.data.organizationTitle);
        store.commit('myAccount/isAdminUser', response.data.isAdmin);
        store.commit('myAccount/userLocations', response.data.locations);

        store.commit('app/dashboardHomeName', `Wolk dashboard van ${response.data.organizationTitle}`);
      } else {
        store.commit('myAccount/organizationId', null);
        store.commit('myAccount/organizationTitle', null);
        store.commit('myAccount/isAdminUser', false);
        store.commit('myAccount/userLocations', []);

        store.commit('app/dashboardHomeName', 'Wolk dashboard');
      }
    });
};

router.beforeEach(async (to, _from, next) => {
  const now = Math.floor(new Date().getTime() / 1000);

  if (!store.state.auth.jwt || store.state.auth.exp < now) {
    store.dispatch('myAccount/reset');

    if (!nonSignedInRouteNames.includes(to.name)) {
      next({ name: 'signIn' });
      return;
    }

    next();
    return;
  }

  if (!store.state.myAccount.type) {
    await loadMyAccount();
  }

  if (!store.state.myAccount.type) {
    next({ name: 'signIn' });
    return;
  }

  next();
});

export default router;
