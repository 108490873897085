<template>
  <v-app>
    <the-snackbar />

    <template v-if="!fullPageRoute">
      <app-bar />

      <navigation-drawer />
    </template>

    <v-main style="margin-bottom: 50px;">
      <router-view />
    </v-main>

    <v-footer
      app
      color="blue-grey lighten-2"
      class="white--text"
    >
      Ziet u onjuistheden?
      Of heeft u opmerkingen/verbeterpunten?
      Meld het ons via

      <a
        class="white--text ml-1"
        href="mailto:feedback@wolkairbag.com"
      >
        feedback@wolkairbag.com
      </a>

      <v-spacer />

      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue';
import NavigationDrawer from './components/NavigationDrawer.vue';
import TheSnackbar from './components/TheSnackbar.vue';

import { nonSignedInRouteNames } from './helpers/auth';

export default {
  name: 'App',
  components: {
    AppBar,
    NavigationDrawer,
    TheSnackbar,
  },
  computed: {
    fullPageRoute() {
      return nonSignedInRouteNames.includes(this.$route.name);
    },
  },
  beforeCreate() {
    this.$store.dispatch('dev/loadSettings');
    this.$store.dispatch('app/loadWolkPanelStates');
    this.$store.dispatch('analytics/loadOrInitialize');
  },
};
</script>
