export default {
  namespaced: true,
  state: {
    type: null,
    firstName: null,
    lastName: null,
    email: null,
    userMetaData: {
      isAdmin: false,
      organizationId: null,
      organizationTitle: null,
      locations: [],
    },
  },
  mutations: {
    type: (state, payload) => {
      state.type = payload;
    },
    firstName: (state, payload) => {
      state.firstName = payload;
    },
    lastName: (state, payload) => {
      state.lastName = payload;
    },
    email: (state, payload) => {
      state.email = payload;
    },
    isAdminUser: (state, payload) => {
      state.userMetaData.isAdmin = payload;
    },
    organizationId: (state, payload) => {
      state.userMetaData.organizationId = payload;
    },
    organizationTitle: (state, payload) => {
      state.userMetaData.organizationTitle = payload;
    },
    userLocations: (state, payload) => {
      state.userMetaData.locations = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit('type', null);
      commit('firstName', null);
      commit('lastName', null);
      commit('email', null);

      commit('organizationId', null);
      commit('organizationTitle', null);
      commit('isAdminUser', false);
      commit('userLocations', []);
    },
  },
  getters: {
    dashboardName(state) {
      if (state.type !== 'user') {
        return 'Wolk dashboard';
      }

      if (state.organizationTitle === null) {
        return 'Wolk dashboard';
      }

      return `Wolk dashboard van ${state.organizationTitle}`;
    },
    isAdmin(state) {
      return state.type === 'admin';
    },
    isUser(state) {
      return state.type === 'user';
    },
    isAdminUser(state) {
      return state.type === 'user' && state.userMetaData.isAdmin;
    },
    isNonAdminUser(state) {
      return state.type === 'user' && !state.userMetaData.isAdmin;
    },
  },
};
