<script>
import { Bar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [
    reactiveProp,
  ],
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
        }],
        yAxes: [{
          gridLines: {
            display: true,
            borderDash: [8],
          },
          ticks: {
            beginAtZero: true,
            callback(value) {
              if (Number.isInteger(value)) {
                return value;
              }

              return '';
            },
          },
        }],
      },
    };

    this.renderChart(this.chartData, options);
  },
};
</script>
