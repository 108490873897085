<template>
  <v-navigation-drawer
    v-model="open"
    app
  >
    <v-img
      class="ma-4 mb-0"
      src="../assets/logo.jpg"
    />

    <v-list v-if="signedIn">
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $store.state.auth.metaData.fullName }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ $store.state.auth.metaData.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-list
      nav
      dense
    >
      <v-list-item-group
        v-if="signedInType === 'admin'"
        color="primary"
      >
        <v-list-item
          :to="{ name: 'home' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'users' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Gebruikers
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'organizations' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Organisaties
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'locations' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-home-city-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Locaties
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'wolks' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-cloud</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Wolks
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'events' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-timeline-check-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Events
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'activations' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-timeline-alert-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Vallen
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'notifications' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-message-text</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Notificaties
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'exports' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-database-export-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Exports
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          href="https://www.wolkairbag.com/order"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Order plaatsen
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group
        v-else-if="signedInType === 'user'"
        color="primary"
      >
        <v-list-item
          :to="{ name: 'home' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'users' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Gebruikers
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'locations' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-home-city-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Locaties
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'wolks' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-cloud</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Wolks
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'activations' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-timeline-alert-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Vallen
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'notifications' }"
          exact
        >
          <v-list-item-icon>
            <v-icon>mdi-message-text</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Notificaties
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          href="https://www.wolkairbag.com/order"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Order plaatsen
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group color="primary">
        <v-list-item
          href="https://www.wolkairbag.com/instructies/"
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-information-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Instructies
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-divider />

    <v-list
      v-if="signedInType === 'admin' && $store.state.dev.displayAdministration"
      nav
      dense
    >
      <v-subheader>Administratie</v-subheader>

      <v-list-item
        :to="{ name: 'administrationSearch' }"
        exact
      >
        <v-list-item-icon>
          <v-icon>mdi-magnify</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            Zoeken
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        :to="{ name: 'administrationInsertAndLink' }"
        exact
      >
        <v-list-item-icon>
          <v-icon>mdi-plus-box-multiple</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            Invoeren en koppelen
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-list
      v-if="$store.state.dev.enabled"
      nav
      dense
    >
      <v-subheader>Developer</v-subheader>

      <v-list-item
        :to="{ name: 'devSettings' }"
        exact
      >
        <v-list-item-icon>
          <v-icon>mdi-dev-to</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            Dev settings
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="signedInType === 'admin'"
        :to="{ name: 'apiApps' }"
        exact
      >
        <v-list-item-icon>
          <v-icon>mdi-api</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            API Apps
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="signedInType === 'admin'"
        :to="{ name: 'alerts' }"
        exact
      >
        <v-list-item-icon>
          <v-icon>mdi-alert</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            Alerts
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-if="signedIn">
      <v-divider />

      <v-list
        nav
        dense
      >
        <v-list-item-group
          color="primary"
        >
          <v-list-item
            exact
            @click="signOut"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-arrow-right</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                Uitloggen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  computed: {
    open: {
      get() {
        return this.$store.state.app.navigationDrawerOpen;
      },
      set(val) {
        this.$store.commit('app/navigationDrawerOpen', val);
      },
    },
    signedIn() {
      return !!this.$store.state.auth.type;
    },
    signedInType() {
      return this.$store.state.auth.type;
    },
  },
  methods: {
    signOut() {
      this.$store.commit('auth/jwtData', null);
      this.$store.commit('app/dashboardHomeName', 'Wolk dashboard');

      this.$router.push({ name: 'signIn' });
    },
  },
};
</script>
