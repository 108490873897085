<template>
  <v-container v-if="!signedIn">
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn :to="{ name: 'signIn' }">
          Inloggen
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="900px"
      persistent
    >
      <v-card
        style="overflow: hidden"
      >
        <v-row>
          <v-col>
            <v-card-title>NIEUW! <br> Retouren via Wolk Dashboard</v-card-title>
            <v-card-text>
              Vanaf nu kun je eenvoudig via het dashboard een maat omruilen of een defect melden.
              Het dashboard helpt je stapsgewijs door het proces en
              na bevestiging ontvang je het retourlabel per e-mail.
              Daarnaast heb je inzicht in welke Wolk(s) je retour hebt gemeld.
              <br>
              <br>
              Hoe meld je een retour aan? Bekijk de instructievideo voor alle stappen.
            </v-card-text>
          </v-col>
          <v-col>
            <iframe
              width="400"
              height="250"
              src="https://www.youtube.com/embed/03vu2esbRH4"
              style="margin-top:50px;margin-right:50px"
              allowfullscreen
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-col>
            <v-row>
              <v-checkbox
                v-model="disablePopup"
                label="Deze melding niet meer tonen"
              />
            </v-row>
            <v-row>
              <v-btn
                color="primary"
                text
                @click="exitDialog"
              >
                Sluit melding
              </v-btn>
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <analytics-v3-block
      v-if="$store.state.auth.type === 'admin' || $store.state.auth.metaData.organizationId"
      :scope="$store.state.auth.type === 'admin' ? 'global' : 'organization'"
      :scope-id="
        $store.state.auth.type === 'admin'
          ? null
          : $store.state.auth.metaData.organizationId
      "
    />

    <monthly-statistics-block
      v-if="showMonthlyStatistics"
      :scope="$store.state.auth.type === 'admin' ? 'global' : 'organization'"
      :scope-id="
        $store.state.auth.type === 'admin'
          ? null
          : $store.state.auth.metaData.organizationId
      "
    />

    <div v-if="Object.keys(locationAnalyticsDataEntries).length > 0">
      <wolk-statuses-block
        v-for="(lade, index) in Object.values(locationAnalyticsDataEntries)"
        :key="index"
        :analytics-data="lade"
        :title="`Wolk statussen van ${lade.title}`"
      />
    </div>

    <wolk-statuses-block
      v-else-if="analyticsData"
      :analytics-data="analyticsData"
    />
  </v-container>
</template>

<script>
import axios from 'axios';

import { createBreadcrumbs } from '../helpers/humanizer';

import AnalyticsV3Block from '../components/AnalyticsV3Block.vue';
import MonthlyStatisticsBlock from '../components/MonthlyStatisticsBlock.vue';
import WolkStatusesBlock from '../components/WolkStatusesBlock.vue';

export default {
  name: 'Home',
  components: {
    AnalyticsV3Block,
    MonthlyStatisticsBlock,
    WolkStatusesBlock,
  },
  data() {
    return {
      loading: false,
      analyticsData: null,
      locationAnalyticsDataEntries: {},
      dialog: false,
      user: null,
      retourEnabled: false,
      disablePopup: false,
    };
  },
  computed: {
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [],
        true,
      );
    },
    signedIn() {
      return !!this.$store.state.auth.type;
    },
    showGlobalAnalytics() {
      return this.$store.state.myAccount.type === 'admin';
    },
    showOrganizationAnalytics() {
      return this.$store.state.myAccount.type === 'user'
        && typeof this.$store.state.myAccount.userMetaData.organizationId === 'string'
        && this.$store.state.myAccount.userMetaData.isAdmin;
    },
    showLocationAnalytics() {
      return this.$store.state.myAccount.type === 'user'
        && typeof this.$store.state.myAccount.userMetaData.organizationId === 'string'
        && !this.$store.state.myAccount.userMetaData.isAdmin;
    },
    showMonthlyStatistics() {
      return this.$store.getters['auth/isAdminUser'] || this.$store.getters['auth/isAdmin'];
    },
  },
  beforeMount() {
    if (this.$store.state.myAccount.type === 'user') {
      this.getUser();
    }
    if (this.showLocationAnalytics) {
      this.$store.state.myAccount.userMetaData.locations.forEach((location) => {
        this.loadAnalyticsData(location);
      });
    }

    this.loadAnalyticsData();
  },
  methods: {
    showDialog() {
      if (typeof this.$store.state.myAccount.userMetaData.organizationId === 'string'
        && this.$store.state.myAccount.userMetaData.isAdmin
        && (this.user.showPopup === undefined || this.user.showPopup)
        && this.retourEnabled) {
        this.dialog = true;
      }
    },
    loadAnalyticsData(location) {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/analytics`;
      const params = {};

      if (location) {
        params.locationId = location._id;
      }

      axios({
        method: 'get',
        url,
        params,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          if (location) {
            this.locationAnalyticsDataEntries[location._id] = {
              ...response.data,
              title: location.title,
            };

            return;
          }

          this.analyticsData = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exitDialog() {
      this.dialog = false;
      if (this.disablePopup) {
        const url = `${config.VUE_APP_API_BASE_URL}/users/${this.user._id}`;
        return axios({
          url,
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.jwt}`,
          },
          method: 'patch',
          data: {
            showPopup: false,
          },
        });
      }
      return false;
    },
    getUser() {
      const url = `${config.VUE_APP_API_BASE_URL}/users/${this.$store.state.auth.id}`;
      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      }).then((response) => {
        const { user } = response.data;
        if (user) {
          this.user = user;
          this.retourEnabled = response.data?.organization?.retourEnabled;
        }
      })
        .finally(() => {
          this.showDialog();
        });
    },
  },
};
</script>
