<template>
  <div>
    <div v-if="snackbarSuccess">
      <v-snackbar
        v-model="snackbarVisible"
        color="primary"
        absolute
        text
        centered
        :timeout="-1"
      >
        {{ snackbarText }}

        <v-btn
          text
          @click="snackbarVisible = false"
        >
          Sluit
        </v-btn>
      </v-snackbar>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbarVisible"
        color="#F44336"
        text
        :timeout="-1"
        class="py-10"
      >
        {{ snackbarText }}

        <v-btn
          text
          @click="snackbarVisible = false"
        >
          Sluit
        </v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheSnackbar',
  computed: {
    snackbarVisible: {
      get() {
        return this.$store.state.app.snackbar.visible;
      },
      set(value) {
        this.$store.commit('app/snackbarVisible', value);
      },
    },
    snackbarText() {
      return this.$store.state.app.snackbar.text;
    },
    snackbarSuccess() {
      return this.$store.state.app.snackbar.success;
    },
  },
};
</script>
