import { userTypes } from '../helpers/humanizer';

const getDefaultWolkPanelStates = () => ({
  locationHistoryVisible: true,
});

export default {
  namespaced: true,
  state: {
    navigationDrawerOpen: null,
    organizationTitle: null,
    dashboardHomeName: 'Wolk dashboard',
    userTypes: [...userTypes],
    wolkSizes: [
      { key: 'S', title: 'S' },
      { key: 'M', title: 'M' },
      { key: 'L', title: 'L' },
      { key: 'XL', title: 'XL' },
    ],
    snackbar: {
      visible: false,
      text: '',
      success: false,
    },
    wolkPanelStates: getDefaultWolkPanelStates(),
  },
  mutations: {
    navigationDrawerOpen: (state, payload) => {
      state.navigationDrawerOpen = payload;
    },
    dashboardHomeName: (state, payload) => {
      state.dashboardHomeName = payload;
    },
    snackbarVisible: (state, payload) => {
      state.snackbar.visible = payload;
    },
    snackbarSuccess: (state, payload) => {
      state.snackbar.success = payload;
    },
    snackbarText: (state, { text, visible }) => {
      state.snackbar.text = text;

      if ([true, false].includes(visible)) {
        state.snackbar.visible = visible;
      }
    },
    wolkPanelStates: (state, payload) => {
      if (!payload) {
        localStorage.removeItem('panelStates');
        state.wolkPanelStates = getDefaultWolkPanelStates();

        return;
      }

      state.wolkPanelStates = payload;
      localStorage.setItem('panelStates', JSON.stringify(payload));
    },
  },
  actions: {
    loadWolkPanelStates({ commit }) {
      const wolkPanelStatesString = localStorage.getItem('panelStates');

      if (!wolkPanelStatesString) {
        return;
      }

      commit('wolkPanelStates', JSON.parse(wolkPanelStatesString));
    },
  },
};
