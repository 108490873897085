<template>
  <v-row v-if="!loading">
    <v-col cols="12">
      <v-card>
        <v-app-bar
          color="primary"
          dark
        >
          <v-toolbar-title v-if="$store.getters['auth/isAdmin']">
            Wolks ingezet
          </v-toolbar-title>

          <v-toolbar-title v-else>
            Wolks ingezet van {{ $store.state.app.dashboardHomeName }}
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="selectedOverviewOption"
                :items="overviewOptions"
                hide-details
                item-text="nl"
                item-value="en"
                label="Selecteer dataweergave"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <wolk-line-chart
                v-if="months.length > 0"
                :chart-data="lineChartData"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';

import WolkLineChart from './WolkLineChart.vue';

export default {
  name: 'WolkMonthlyStatisticsBlock',
  components: {
    WolkLineChart,
  },
  props: {
    scope: {
      type: String,
      default: 'global',
    },
    scopeId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      months: [],
      overviewOptions: [
        { en: 'total', nl: 'Totaal aantal Wolks ingezet' },
        { en: 'average', nl: 'Gemiddeld aantal Wolks ingezet' },
      ],
      selectedOverviewOption: 'total',
    };
  },
  computed: {
    lineChartData() {
      let data;
      if (this.selectedOverviewOption === 'total') {
        data = this.months.map((m) => m.wolkCount);
      } else {
        data = this.months.map((m) => (m.activeWolkDays / m.daysInMonth).toFixed(0));
      }
      return {
        labels: this.months.map((m) => `${m.monthName} ${m.year}`),
        datasets: [
          {
            label: 'Ingezette wolks',
            data,
          },
        ],
      };
    },
  },
  beforeMount() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;

      const url = `${config.VUE_APP_API_BASE_URL}/analytics/monthly-wolk-usage`;

      const params = {
        scope: this.scope,
        months: 6,
      };

      if (this.scope !== 'global') {
        params.scopeId = this.scopeId;
      }

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        params,
      })
        .then((response) => {
          this.months = response.data.months;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
