import analytics from './analytics';
import app from './app';
import auth from './auth';
import dev from './dev';
import myAccount from './myAccount';

export default ({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    analytics,
    app,
    auth,
    dev,
    myAccount,
  },
});
