<script>
import { Line, mixins } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [
    reactiveProp,
  ],
  mounted() {
    this.addPlugin(ChartDataLabels);

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 22,
        },
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [{
          scaleLabel: {
            display: false,
          },
          ticks: {
            fontSize: 14,
            fontStyle: 'bold',
            padding: 10,
          },
        }],
        yAxes: [{
          scaleLabel: {
            display: false,
          },
          ticks: {
            fontSize: 14,
            fontStyle: 'bold',
            padding: 10,
            beginAtZero: true,
          },
        }],
      },
      plugins: {
        datalabels: {
          color: this.$vuetify.theme.defaults.light.primary,
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          borderRadius: 3,
          // align: 'top',
          anchor: 'end',
          clamp: true,
          align: 'end',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
      },
      elements: {
        line: {
          tension: 0.2,
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: this.$vuetify.theme.defaults.light.primary,
        },
      },
    };

    this.renderChart(this.chartData, options);
  },
};
</script>
