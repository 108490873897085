import { DateTime } from '../helpers/dateTime';

export default {
  namespaced: true,
  state: {
    timeframeKey: 'thisMonth',
    timeframes: [
      {
        key: 'today',
        title: 'Vandaag',
      },
      {
        key: 'yesterday',
        title: 'Gisteren',
      },
      {
        key: 'thisWeek',
        title: 'Deze week',
      },
      {
        key: 'lastWeek',
        title: 'Vorige week',
      },
      {
        key: 'thisMonth',
        title: 'Deze maand',
      },
      {
        key: 'lastMonth',
        title: 'Vorige maand',
      },
      {
        key: 'thisYear',
        title: 'Dit jaar',
      },
      {
        key: 'always',
        title: 'Altijd',
      },
      {
        key: 'custom',
        title: 'Aangepast',
      },
    ],
    fromDate: null,
    toDate: null,
  },
  mutations: {
    timeframe(state, payload) {
      if (!state.timeframes.map((tf) => tf.key).includes(payload.key)) {
        return;
      }

      localStorage.setItem('analyticsTimeframeData', JSON.stringify(payload));

      state.timeframeKey = payload.key;
      state.fromDate = payload.fromDate;
      state.toDate = payload.toDate;
    },
  },
  actions: {
    setTimeframe({ commit }, {
      key,
      fromDate,
      toDate,
    }) {
      commit('timeframe', {
        key,
        fromDate,
        toDate,
      });
    },
    loadOrInitialize({
      commit,
      dispatch,
      state,
    }) {
      const analyticsTimeframeData = localStorage.getItem('analyticsTimeframeData');

      if (!analyticsTimeframeData) {
        commit('timeframe', {
          key: 'thisMonth',
          fromDate: DateTime.local().startOf('month').toISODate(),
          toDate: DateTime.local().toISODate(),
        });

        return;
      }

      const parsedAnalyticsTimeframeData = JSON.parse(analyticsTimeframeData);

      if (parsedAnalyticsTimeframeData && parsedAnalyticsTimeframeData.key) {
        if (
          parsedAnalyticsTimeframeData.key === 'custom'
          && parsedAnalyticsTimeframeData.fromDate
          && parsedAnalyticsTimeframeData.toDate
        ) {
          commit('timeframe', {
            key: 'custom',
            fromDate: parsedAnalyticsTimeframeData.fromDate,
            toDate: parsedAnalyticsTimeframeData.toDate,
          });

          return;
        }

        if (state.timeframes.map((tf) => tf.key).includes(parsedAnalyticsTimeframeData.key)) {
          dispatch('selectPreset', parsedAnalyticsTimeframeData.key);

          return;
        }
      }

      commit('timeframe', {
        key: 'thisMonth',
        fromDate: DateTime.local().startOf('month').toISODate(),
        toDate: DateTime.local().toISODate(),
      });
    },
    selectPreset({ commit }, preset) {
      if (preset === 'today') {
        commit('timeframe', {
          key: preset,
          fromDate: DateTime.local().toISODate(),
          toDate: DateTime.local().toISODate(),
        });

        return;
      }

      if (preset === 'yesterday') {
        commit('timeframe', {
          key: preset,
          fromDate: DateTime.local().minus({ days: 1 }).toISODate(),
          toDate: DateTime.local().minus({ days: 1 }).toISODate(),
        });

        return;
      }

      if (preset === 'thisWeek') {
        if (DateTime.local().startOf('week').toISODate() === DateTime.local().toISODate()) {
          commit('timeframe', {
            key: preset,
            fromDate: DateTime.local().startOf('week').toISODate(),
            toDate: DateTime.local().toISODate(),
          });
        } else {
          commit('timeframe', {
            key: preset,
            fromDate: DateTime.local().startOf('week').toISODate(),
            toDate: DateTime.local().minus({ days: 1 }).toISODate(),
          });
        }

        return;
      }

      if (preset === 'lastWeek') {
        commit('timeframe', {
          key: preset,
          fromDate: DateTime.local().minus({ weeks: 1 }).startOf('week').toISODate(),
          toDate: DateTime.local().minus({ weeks: 1 }).endOf('week').toISODate(),
        });

        return;
      }

      if (preset === 'thisMonth') {
        if (DateTime.local().startOf('month').toISODate() === DateTime.local().toISODate()) {
          commit('timeframe', {
            key: preset,
            fromDate: DateTime.local().startOf('month').toISODate(),
            toDate: DateTime.local().toISODate(),
          });
        } else {
          commit('timeframe', {
            key: preset,
            fromDate: DateTime.local().startOf('month').toISODate(),
            toDate: DateTime.local().minus({ days: 1 }).toISODate(),
          });
        }

        return;
      }

      if (preset === 'lastMonth') {
        commit('timeframe', {
          key: preset,
          fromDate: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
          toDate: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
        });

        return;
      }

      if (preset === 'thisYear') {
        if (DateTime.local().startOf('year').toISODate() === DateTime.local().toISODate()) {
          commit('timeframe', {
            key: preset,
            fromDate: DateTime.local().startOf('year').toISODate(),
            toDate: DateTime.local().toISODate(),
          });
        } else {
          commit('timeframe', {
            key: preset,
            fromDate: DateTime.local().startOf('year').toISODate(),
            toDate: DateTime.local().minus({ days: 1 }).toISODate(),
          });
        }

        return;
      }

      if (preset === 'always') {
        commit('timeframe', {
          key: preset,
          fromDate: DateTime.fromISO('2020-08-01').toISODate(),
          toDate: DateTime.local().minus({ days: 1 }).toISODate(),
        });

        return;
      }

      throw Error(`Can not initialize analyticsTimeframeData with key: ${preset}`);
    },
  },
};
