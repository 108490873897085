export default {
  namespaced: true,
  state: {
    enabled: false,
    showInvalidData: false,
    showMonthlyStatistics: false,
    displayAdministration: true,
  },
  mutations: {
    settings(state, payload) {
      if (!payload || !payload.enabled) {
        localStorage.removeItem('devSettings');
        state.enabled = false;
        state.showInvalidData = false;
        state.showMonthlyStatistics = false;
        state.displayAdministration = true;

        return;
      }

      localStorage.setItem('devSettings', JSON.stringify(payload));

      state.enabled = payload.enabled;
      state.showInvalidData = payload.showInvalidData;
      state.showMonthlyStatistics = payload.showMonthlyStatistics;
    },
    enabled(state, payload) {
      state.enabled = payload;

      localStorage.setItem('devSettings', JSON.stringify(state));
    },
    showInvalidData(state, payload) {
      state.showInvalidData = payload;

      localStorage.setItem('devSettings', JSON.stringify(state));
    },
    showMonthlyStatistics(state, payload) {
      state.showMonthlyStatistics = payload;

      localStorage.setItem('devSettings', JSON.stringify(state));
    },
    displayAdministration(state, payload) {
      state.displayAdministration = payload;

      localStorage.setItem('devSettings', JSON.stringify(state));
    },
  },
  actions: {
    loadSettings({ dispatch }) {
      const devSettingsString = localStorage.getItem('devSettings');

      if (!devSettingsString) {
        return;
      }

      const parsedDevSettings = JSON.parse(devSettingsString);
      if (typeof parsedDevSettings.enabled === 'boolean') {
        dispatch('setEnabled', parsedDevSettings.enabled);
      }

      if (typeof parsedDevSettings.showInvalidData === 'boolean') {
        dispatch('setShowInvalidData', parsedDevSettings.showInvalidData);
      }

      if (typeof parsedDevSettings.showMonthlyStatistics === 'boolean') {
        dispatch('setShowMonthlyStatistics', parsedDevSettings.showMonthlyStatistics);
      }

      if (typeof parsedDevSettings.displayAdministration === 'boolean') {
        dispatch('setDisplayAdministration', parsedDevSettings.displayAdministration);
      } else {
        dispatch('setDisplayAdministration', false);
      }
    },
    setEnabled({ commit }, value) {
      if (typeof value === 'boolean') {
        commit('enabled', value);
      }
    },
    setShowInvalidData({ commit }, value) {
      if (typeof value === 'boolean') {
        commit('showInvalidData', value);
      }
    },
    setShowMonthlyStatistics({ commit }, value) {
      if (typeof value === 'boolean') {
        commit('showMonthlyStatistics', value);
      }
    },
    setDisplayAdministration({ commit }, value) {
      if (typeof value === 'boolean') {
        commit('displayAdministration', value);
      }
    },
  },
};
