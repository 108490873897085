const wolkStatuses = [
  {
    key: 'offline',
    title: 'Offline',
    color: '#D3D3D3',
    inversTextColor: false,
  },
  {
    key: 'protectionActive',
    title: 'Bescherming actief',
    color: '#4CB14C',
    inversTextColor: false,
  },
  {
    key: 'wornIncorrectly',
    title: 'Wasmachine',
    color: '#FFD921',
    inversTextColor: false,
  },
  {
    key: 'charging',
    title: 'Aan de oplader',
    color: '#ADD8E6',
    inversTextColor: false,
  },
  {
    key: 'replaceCartridge',
    title: 'Vervang patroon',
    color: '#FF4500',
    inversTextColor: false,
  },
  {
    key: 'error',
    title: 'Technische storing',
    color: '#C50A0A',
    inversTextColor: true,
  },
  {
    key: 'batteryLow',
    title: 'Batterij laag',
    color: '#FF8C00',
    inversTextColor: false,
  },
  {
    key: 'unknown',
    title: 'Onbekend',
    color: '#C0C0C0',
    inversTextColor: false,
  },
];

const getStatusInfo = (statusKey) => {
  if (!statusKey || typeof statusKey !== 'string') {
    return wolkStatuses.find((ws) => ws.key === 'unknown');
  }

  const foundWolkStatus = wolkStatuses.find((ws) => ws.key === statusKey);
  if (!foundWolkStatus) {
    return wolkStatuses.find((ws) => ws.key === 'unknown');
  }

  return foundWolkStatus;
};

const getStatusColor = (statusKey) => {
  const foundWolkStatus = getStatusInfo(statusKey);

  return foundWolkStatus.color;
};

const getHumanizedDate = (millis) => {
  if (!millis) {
    return 'Onbekend';
  }

  return new Date(millis).toLocaleString('nl-NL');
};

const getHumanizedFallIntensity = (key) => {
  let text = 'onbekend';
  let color = '#C0C0C0';

  if (key === 'very soft') {
    text = 'Zeer zacht';
    color = '#FEF2CE';
  }

  if (key === 'soft') {
    text = 'Zacht';
    color = '#FFE59E';
  }

  if (key === 'hard') {
    text = 'Hard';
    color = '#FFCE8F';
  }

  if (key === 'very hard') {
    text = 'Zeer hard';
    color = '#F2AF86';
  }

  return {
    text,
    color,
  };
};

const getHumanizedWolkStatusTitle = (statusKey) => {
  const wolkStatus = getStatusInfo(statusKey);

  return wolkStatus.title;
};

const getHumanizedFirmwareVersionInfo = (input) => {
  const returnValue = {
    hasWarning: false,
    color: '#C0C0C0',
    text: '--',
  };

  if (typeof input !== 'object') {
    return returnValue;
  }

  if (typeof input.firmwareVersion !== 'number') {
    return returnValue;
  }

  returnValue.text = `${input.firmwareVersion}`;

  if (typeof input.firmwareVersionDiff !== 'number') {
    return returnValue;
  }

  if (input.firmwareVersionDiff === 0) {
    returnValue.hasWarning = false;
    returnValue.color = '#4CB14C'; // Green
  } else if (input.firmwareVersionDiff > 0) {
    returnValue.hasWarning = true;
    returnValue.color = '#FF8D00'; // Orange
  } else if (input.firmwareVersionDiff < -2) {
    returnValue.hasWarning = true;
    returnValue.color = '#FF8D00'; // Orange
  } else {
    // firmwareVersionDiff is -1 or -2
    returnValue.hasWarning = true;
    returnValue.color = '#FFD700'; // Yellow
  }

  return returnValue;
};

const userTypes = [
  {
    key: 'doctor',
    title: 'Arts',
  },
  {
    key: 'ict',
    title: 'ICT',
  },
  {
    key: 'financial',
    title: 'Inkoop/Financieel',
  },
  {
    key: 'innovation',
    title: 'Innovatiemanager/Adviseur',
  },
  {
    key: 'middleManagement',
    title: 'Management',
  },
  {
    key: 'marketing',
    title: 'Marketing/Communicatie',
  },
  {
    key: 'privacy',
    title: 'Privacy',
  },
  {
    key: 'practitioner',
    title: 'Behandelaar (Fysio/Ergo)',
  },
  {
    key: 'caregiver',
    title: 'Verpleegkundige/Verzorgende',
  },
  {
    key: 'policyOfficer',
    title: 'Beleidsadviseur/Medewerker',
  },
  {
    key: 'management',
    title: 'Bestuur/Hoger management',
  },
];

const getHumanizedUserType = (userType) => {
  const foundUserType = userTypes.find((t) => t.key === userType);

  if (!foundUserType) {
    return 'Onbekend';
  }

  return foundUserType.title;
};

const getHumanizedSide = (side, analysedType, type) => {
  if (type === 'fall') {
    if ([1, 2].includes(side) && analysedType === 'non-hip fall forward') {
      return 'Naar voren';
    }
    if ([1, 2].includes(side) && analysedType === 'non-hip fall backward') {
      return 'Naar achteren';
    }
    if ([1, 2, 4, 5].includes(side) && ['other', 'uncertain'].includes(analysedType)) {
      return 'N.v.t.';
    }
  }

  if (type === 'postFall') {
    if ([1, 2].includes(side) && analysedType === 'non-hip fall forward') {
      return 'Naar voren';
    }
    if ([1, 2].includes(side) && analysedType === 'non-hip fall backward') {
      return 'Naar achteren';
    }
    if ([4, 5].includes(side) && analysedType === 'hip fall right') {
      return 'Heupval - rechts';
    }
    if ([4, 5].includes(side) && analysedType === 'hip fall left') {
      return 'Heupval - links';
    }
    if ([1, 2, 4, 5].includes(side) && ['other', 'uncertain'].includes(analysedType)) {
      return 'N.v.t.';
    }
  }

  if (side === 1) {
    return 'Heupval - rechts';
  }

  if (side === 2) {
    return 'Heupval - links';
  }

  if (side === 4) {
    return 'Naar voren';
  }

  if (side === 5) {
    return 'Naar achteren';
  }

  return 'N.v.t.';
};

const getHumanizedBatteryPercentage = (wolk, currently = false) => {
  let color = '#CCC';

  if (!wolk || !wolk.currentStatusInfo) {
    return {
      name: 'Onbekend',
      color,
    };
  }

  if (currently && wolk.currentStatus && wolk.currentStatus === 'offline') {
    return {
      name: '--',
      color,
    };
  }

  if (wolk.currentStatusInfo.parsedStatusKey === 'charging') {
    return {
      name: 'Opladen',
      color,
    };
  }

  if (!wolk.currentStatusInfo.batteryPercentage) {
    return {
      name: 'Onbekend',
      color,
    };
  }

  if (wolk.currentStatusInfo.batteryPercentage > 70) {
    color = '#4CB14C'; // Green
  } else if (wolk.currentStatusInfo.batteryPercentage > 40) {
    color = '#FFD700'; // Yellow
  } else if (wolk.currentStatusInfo.batteryPercentage > 20) {
    color = '#FFA500'; // Orange
  } else {
    color = '#FF4500'; // Red
  }

  return {
    name: `${wolk.currentStatusInfo.batteryPercentage}%`,
    color,
  };
};

const toHumanizedStatistics = (statistics, options = {}) => {
  if (options.debug) {
    console.log(statistics);
  }

  const humanizedStatistics = [];

  Object.entries(statistics).forEach(([key, value]) => {
    if (key === 'activeSeconds') {
      humanizedStatistics.push([
        'Dagen actief',
        Math.floor(value / 60 / 60 / 8),
      ]);

      return;
    }

    if (key === 'stepsTaken') {
      humanizedStatistics.push([
        'Aantal meters gelopen',
        Math.floor(value / 2),
      ]);

      return;
    }

    if (key === 'wornSeconds') {
      humanizedStatistics.push([
        'Aantal uren gedragen',
        Math.floor(value / 60 / 60),
      ]);
    }
  });

  return humanizedStatistics;
};

const createBreadcrumbs = (
  dashboardHomeName = 'Wolk dashboard',
  pageBreadcrumbs = [],
  isHomePage = false,
) => [
  {
    text: dashboardHomeName,
    disabled: isHomePage,
    exact: true,
    to: { name: 'home' },
  },
  ...pageBreadcrumbs,
];

export {
  getHumanizedBatteryPercentage,
  getHumanizedDate,
  getHumanizedFallIntensity,
  getHumanizedSide,
  getStatusColor,
  getStatusInfo,
  userTypes,
  getHumanizedUserType,
  getHumanizedWolkStatusTitle,
  getHumanizedFirmwareVersionInfo,
  toHumanizedStatistics,
  createBreadcrumbs,
};
